/* eslint-disable jsx-a11y/anchor-is-valid */
/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

import { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { useFormik } from 'formik'
import { preLogin } from '../core/_requests'
import { useAuth } from '../core/Auth'
import { Modal } from 'react-bootstrap'
import axios from 'axios'
import { setAuth } from '../../../store/slice/authSlice'
import { useDispatch } from 'react-redux'
import { Dispatch } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import ls from "localstorage-slim";
import { cryptoEncrypt } from '../../../utils/dataTransit'
import OtpInput from 'react-otp-input';
import "../../../css/otp-input.css"
import { Link } from 'react-router-dom'

// const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong Email Format.')
    .max(50, 'Maximum 50 symbols')
    .required('Email is Required'),
  password: Yup.string()
    .required("Password is Required"),
  // .min(10, "Too Short")
  // .max(14, "Too Long")
  // .required('Number is Required'),
  // .matches(phoneRegExp, 'Phone number is not valid')
})

const initialValues = {
  email: '',
  password: '',
}

export function Login() {
  const [passwordType, setPasswordType] = useState("password")
  const [loading, setLoading] = useState(false)
  const { saveAuth, setCurrentUser } = useAuth()
  const [show, setShow] = useState(false)
  const [email, setEmail] = useState('')
  const [otpCode, setOtpCode] = useState('')
  const [policyAccepted, setPolicyAccepted] = useState(false)

  // const [res2FA, setRes2FA] = useState({
  //   msg: '',
  //   qrCode: ''
  // })
  const dispatch: Dispatch<any> = useDispatch();

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text")
      return;
    }
    setPasswordType("password")
  }

  // useEffect(() => {
  //   countDownTimer > 0 && setTimeout(() => setCountDownTimer(countDownTimer - 1), 1000);
  //   countDownTimer === 0 && setCountDownTimer(59)
  //   // countDownTimer === -1 && setCountDownTimer(-1)
  // }, [countDownTimer]);

  // console.log(countDownTimer);



  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values) => {

      try {
        setLoading(true)
        const res = await preLogin(values.email, values.password)
        toast.success(res.data.message)
        setLoading(false)
        setShow(true)
        // setCountDownTimer(0)

        // try {

        //   let twoFAResponse = await initiate2FA(values.email)
        //   console.log("twoFAResponse", twoFAResponse);
        //   setRes2FA({

        //     msg: twoFAResponse.data.message,
        //     qrCode: twoFAResponse.data.qrcode

        //   })

        // } catch (error: any) {
        //   return error
        // }
      } catch (error: any) {
        // toast.error(error.response.data.message)
        toast.error("Sorry, that email or password didn't work.")
        setLoading(false)
      }
      setEmail(values.email)
    },
  })

  const verifyLogin = async () => {
    let encData = cryptoEncrypt({
      email,
      otp: otpCode,
    })
    try {
      const auth: any = await axios.post(`${process.env.REACT_APP_API_BACKEND}/auth/verify/login/2fa`, { data: encData })
      // localStorage.setItem('auth', JSON.stringify(auth.data))

      ls.set("u0@t", JSON.stringify(auth.data), { encrypt: true });

      // const token: any = ls.get("u0@t", { decrypt: true })
      axios.defaults.headers['Authorization'] = 'Bearer ' + auth.data.token
      saveAuth(auth.data)
      dispatch(setAuth(auth.data))
      setCurrentUser(auth.data)
    } catch (error: any) {

      setOtpCode("")

      toast.error(error.response.data.message)
      // console.log("error.response.data", error.response.status);

      saveAuth(undefined)
    }
  }


  return (
    <>

      <Modal
        className='modal fade'
        id='kt_modal_select_location'
        data-backdrop='static'
        tabIndex={-1}
        role='dialog'
        show={show}
        centered
        // dialogClassName='modal-xl'
        aria-hidden='true'
      >
        <div className='modal-content'>
          <div className='modal-body text-center'>
            {/* <h4>{res2FA.msg}</h4> */}
            {/* <img src={res2FA.qrCode} /> */}
            <h6 className="mt-3 mb-5">Enter Practicewisedds Code from Authenticator Mobile App</h6>
            <div className='d-flex justify-content-center'>

              <div className='my-5'>

                {/* <input
                  placeholder='Enter Code'
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  onChange={(e) => setOtpCode(e.target.value)}
                  onKeyPress={(e) => e.key === "Enter" && otpCode.length > 0 && verifyLogin()}
                /> */}
                <OtpInput
                  value={otpCode}
                  onChange={(otp: any) => setOtpCode(otp)}
                  numInputs={6}
                  separator={<span>-</span>}
                  inputStyle="otp-input-style"
                />

                {/* <p className='text-center mt-5 mb-0'>Code will change after:</p> */}
                {/* <span className='text-primary'>00:{countDownTimer}</span> */}
              </div>
            </div>

          </div>
          <div className='modal-footer d-flex justify-content-center'>
            <button type='button' className='btn btn-light-primary mx-3' onClick={() => {
              setShow(false);
              //  setCountDownTimer(-1); 
              setOtpCode("")
            }}>
              Cancel
            </button>
            <button type='button' disabled={otpCode.length > 0 ? false : true} className='btn btn-primary mx-3' onClick={() => otpCode.length > 0 && verifyLogin()} >
              Sign-In
            </button>
          </div>
        </div>
      </Modal>
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate
        id='kt_login_signin_form'
      >
        <div className='text-center mb-10'>
          <h1 className='text-dark mb-3'>Sign In to PracticeWise</h1>
        </div>
        <div className='fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
          <input
            placeholder='Enter Email'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              { 'is-invalid': formik.touched.email && formik.errors.email },
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
            type='email'
            name='email'
            autoComplete='off'
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <span role='alert' style={{ color: 'red' }}>{formik.errors.email}</span>
            </div>
          )}
        </div>

        <div className='fv-row mb-10'>
          <div className='d-flex justify-content-between mt-n5'>
            <div className='d-flex flex-stack mb-2'>
              <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
            </div>
          </div>
          <div className="input-group">
            <input
              type={passwordType}
              placeholder='Enter Your Password'
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            <div className="input-group-btn">
              <button className="btn border-end border-top border-bottom" style={{ borderRadius: "0px 10px 10px 0px" }} type="button" onClick={togglePassword}>
                {passwordType === "password" ? <i className="bi bi-eye-slash"></i> : <i className="bi bi-eye"></i>}
              </button>
            </div>
          </div>

          {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert' style={{ color: 'red' }}>{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>

        <div className="form-check mb-4">
          <input
            className="form-check-input"
            type="checkbox"
            checked={policyAccepted}
            id="flexCheckDefault"
            onChange={(e) => setPolicyAccepted(e.target.checked)}
          />
          <label
            className="form-check-label fw-semibold "
            htmlFor="flexCheckDefault"
          >
            By Logging in, your are agreeing to Practicewisedds <a href={`${process.env.REACT_APP_PWDDS_URL}/terms`} target="_blank">Terms &amp; Conditions</a> and <a href={`${process.env.REACT_APP_PWDDS_URL}/privacy-policy`} target="_blank">Privacy Policy.</a>
          </label>
          {/* <p className='px-5 py-3 text-center'>   
          </p> */}
        </div>
        <div className='text-center'>

          <button
            type='submit'

            id='kt_sign_in_submit'
            className='btn btn-lg btn-primary w-100 mb-5'
            disabled={!policyAccepted || formik.isSubmitting || !formik.isValid}
          >
            {!loading && <span className='indicator-label'>Continue</span>}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <p>
            <Link to="/auth/forgot-password">Forgot password?</Link>
          </p>

          {/* {successStatus && <Alert variant='primary'>{successMsg}</Alert>}
          {errorStatus && <Alert variant='danger'>{errorMsg}</Alert>} */}
        </div>
        {/* end::Action */}
      </form>
    </>
  )
}
