import {createSlice} from '@reduxjs/toolkit'

const initialState: any = {
  studentLoans: {},
}

export const spinwheelStudentLoansSlice = createSlice({
  name: 'studentLoans',
  initialState,
  reducers: {
    setStudentLoans: (state, action) => {
      state.studentLoans = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {setStudentLoans} = spinwheelStudentLoansSlice.actions

export default spinwheelStudentLoansSlice.reducer
