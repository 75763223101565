import {configureStore} from '@reduxjs/toolkit'
import loansSlice from './slice/loansSlice'
import addLoansSlice from './slice/addLoansSlice'
import packagePlanSlice from './slice/getPackagePlan'
import authSlice from './slice/authSlice'
import originalPlanSlice from './slice/originalPlanSlice'
import getCustomerDataSlice from './slice/getCustomerDataSlice'
import imageSlice from './slice/imageSlice'
import dwollaCustomerSlice from './slice/dwollaCustomerSlice'
import dwollaTransactionsSlice from './slice/dwollaTransactionsSlice'
import dwollaFundingSourcesSlice from './slice/dwollaFundingSourcesSlice'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {combineReducers} from '@reduxjs/toolkit'
import spinwheelStudentLoansSlice from './slice/spinwheelStudentLoansSlice'
import spinwheelLoanAccountsSlice from './slice/spinwheelLoanAccountsSlice'
import spinWheelStudentLoanAccountsDetailsSlice from './slice/spinWheelStudentLoanAccountsDetailsSlice'
import {encryptTransform} from 'redux-persist-transform-encrypt'

const reducers = combineReducers({
  loansReducer: loansSlice,
  addLoansReducer: addLoansSlice,
  packagePlanReducer: packagePlanSlice,
  getCustomerData: getCustomerDataSlice,
  authReducer: authSlice,
  spinwheelStudentLoansReducer: spinwheelStudentLoansSlice,
  originalPlan: originalPlanSlice,
  imageResponse: imageSlice,
  dwollaCustomerReducer: dwollaCustomerSlice,
  dwollaTransactionsReducer: dwollaTransactionsSlice,
  dwollaFundingSourcesReducer: dwollaFundingSourcesSlice,
  spinwheelLoanAccountsReducer: spinwheelLoanAccountsSlice,
  spinWheelStudentLoanAccountsDetailsReducer: spinWheelStudentLoanAccountsDetailsSlice,
})

const encryptor = encryptTransform({
  secretKey: 'my-super-secret-key',
  onError: function (error) {
    // Handle the error.
  },
})

const persistConfig = {
  key: 'root',
  storage,
  transforms: [encryptor],
}
const persistedReducer = persistReducer(persistConfig, reducers)
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
// export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
// export type AppDispatch = typeof store.dispatch
// export const useAppDispatch = () => useDispatch<AppDispatch>()
