import { FC, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

const NetworkConnection: FC = () => {
  const navigate = useNavigate()
  useEffect(() => {
    let timer = setInterval(function () {
      let con = (window as any).navigator.onLine
      if (con === true) {
        navigate('/')
      }
    }, 3000);

    return () => clearInterval(timer)

  }, [])

  return (
    <>
      <h1 className='fw-bolder fs-4x text-gray-700 mb-10'>Connection Lost</h1>
      {/* <div className='text-center'>
              <Link to='/' className='btn btn-lg btn-primary fw-bolder'>
                Go to homepage
              </Link>
            </div> */}

      <div className='fw-bold fs-3 text-gray-400 mb-15'>
        Please Check your network connection <br /> and try again later.
      </div>
    </>
  )
}

export { NetworkConnection }
