import {createSlice} from '@reduxjs/toolkit'

const initialState: any = {
  originalPlan: [],
}

export const originalPlanSlice = createSlice({
  name: 'plan',
  initialState,
  reducers: {
    originalPlanAction: (state, action) => {
      state = {...state, originalPlan: action.payload}
    },
  },
})

// Action creators are generated for each case reducer function
export const {originalPlanAction} = originalPlanSlice.actions

export default originalPlanSlice.reducer
