import {createSlice} from '@reduxjs/toolkit'

const initialState: any = {
  dwollaCustomer: {},
  loading: false,
}

export const dwollaCustomerSlice = createSlice({
  name: 'dwollaCustomer',
  initialState,
  reducers: {
    getDwollaCustomer: (state, action) => {
      state.dwollaCustomer = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {getDwollaCustomer} = dwollaCustomerSlice.actions

export default dwollaCustomerSlice.reducer
