import {createSlice} from '@reduxjs/toolkit'

const initialState: any = {
  dwollaTransactions: {},
}

export const dwollaTransactionsSlice = createSlice({
  name: 'dwollaTransactions',
  initialState,
  reducers: {
    getDwollaTransactions: (state, action) => {
      state.dwollaTransactions = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {getDwollaTransactions} = dwollaTransactionsSlice.actions

export default dwollaTransactionsSlice.reducer
