import {createSlice} from '@reduxjs/toolkit'

const initialState: any = {
  accountDetails: {},
}

export const spinWheelStudentLoanAccountsDetailsSlice = createSlice({
  name: 'accountDetails',
  initialState,
  reducers: {
    setStudentLoanAccountsDetails: (state, action) => {
      state.accountDetails = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {setStudentLoanAccountsDetails} = spinWheelStudentLoanAccountsDetailsSlice.actions

export default spinWheelStudentLoanAccountsDetailsSlice.reducer
