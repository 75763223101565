import {createAsyncThunk} from '@reduxjs/toolkit'
// import instance from '../../utils/axiosInstance'
import axios from 'axios'
import ls from 'localstorage-slim'

export const getLoans = createAsyncThunk<any>('getLoans', async () => {
  const userData: any = ls.get('u0@t', {decrypt: true})
  const data = JSON.parse(userData)
  try {
    const res: any = await axios.get(
      `${process.env.REACT_APP_API_BACKEND}/creditor/debt-staking/${data.data.id}`
    )
    return res
  } catch (error) {
    return error
  }
})
