import {createSlice} from '@reduxjs/toolkit'

interface ILoans {
  loans: any
}

const initialState: ILoans = {
  loans: [],
}

export const addLoansSlice = createSlice({
  name: 'values',
  initialState,
  reducers: {
    addLoans: (state, action) => {
      state = {...state, loans: action.payload}
      return state
    },
  },
})

// Action creators are generated for each case reducer function
export const {addLoans} = addLoansSlice.actions

export default addLoansSlice.reducer
