import {createSlice} from '@reduxjs/toolkit'
import {getPackagePlan} from '../actions/getPackagePlan'

const initialState: any = {
  packagePlan: {},
  loading: false,
  errors: {},
}

export const packagePlanSlice = createSlice({
  name: 'packagePlanSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPackagePlan.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(getPackagePlan.fulfilled, (state, action) => {
      state.loans = {...action.payload}
      state.loading = false
    })
    builder.addCase(getPackagePlan.rejected, (state, action) => {
      state.errors = action.payload
    })
  },
})

export default packagePlanSlice.reducer
