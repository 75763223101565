import axios from 'axios'
import {toast} from 'react-toastify'
import {cryptoEncrypt} from '../../utils/dataTransit'

export const getDwalloToken = async () => {
  let encData = cryptoEncrypt({pw_secret: process.env.REACT_APP_PW_SECRET})

  try {
    let res = await axios.post(`${process.env.REACT_APP_API_BACKEND}/dwolla/tokenUrl`, {
      data: encData,
    })

    return res.data.token
  } catch (error) {
    return error
  }
}

export const createDwollaCustomer = async (data: any, navigate: any) => {
  let encData = cryptoEncrypt(data)
  try {
    let res = await axios.post(`${process.env.REACT_APP_API_BACKEND}/dwolla/create/customer`, {
      data: encData,
    })
    toast.success('You are Verified!')
    setTimeout(() => {
      navigate('/debt-resolution/debt')
    }, 4000)
    let customerId = res.data.customerId.slice(41)
    return customerId
  } catch (error: any) {
    toast.error(error.response.data.data._embedded.errors[0].message)

    return error
  }
}

export const getCustomer = async (token: any, email: any, total = 100) => {
  let encData = cryptoEncrypt({
    accesstoken: token,
  })
  try {
    let res = await axios.post(
      `${process.env.REACT_APP_API_BACKEND}/dwolla/customers?limit=${total}&offset=0`,
      {data: encData}
    )

    let customers
    let customer
    let newTotal = res.data.data.total
    let resAgain
    if (newTotal > total) {
      resAgain = await axios.post(
        `${process.env.REACT_APP_API_BACKEND}/dwolla/customers?limit=${newTotal}&offset=0`,
        {data: encData}
      )

      customers = resAgain.data.data._embedded.customers
      customer = customers.find((item: any) => item.email === email)
    }

    customers = res.data.data._embedded.customers
    customer = customers.find((item: any) => item.email === email)

    return customer
  } catch (error: any) {
    return error
  }
}
export const getFundingResources = async (id: any, token: any) => {
  let encData = cryptoEncrypt({
    accesstoken: token,
    customerId: id,
  })

  try {
    let res = await axios.post(
      `${process.env.REACT_APP_API_BACKEND}/dwolla/customer/funding-source`,
      {
        data: encData,
      }
    )

    return res.data.data._embedded
  } catch (error: any) {
    return error
  }
}

export const createIAVToken = async (id: any, token: any) => {
  let encData = cryptoEncrypt({
    accesstoken: token,
    customerId: id,
  })
  try {
    let res = await axios.post(`${process.env.REACT_APP_API_BACKEND}/dwolla/customer/iav-token`, {
      data: encData,
    })

    return res.data.data.token
  } catch (error: any) {
    return error
  }
}

export const verifyMicroDeposits = async (token: any, id: any, data: any) => {
  let updatedData = {...data}
  updatedData.accesstoken = token
  updatedData.fundingsourceId = id
  let encData = cryptoEncrypt(updatedData)
  try {
    await axios.post(
      `${process.env.REACT_APP_API_BACKEND}/dwolla/funding-source/verify/micro-deposit`,
      {data: encData}
    )

    toast.success('Funding Source Verified')
  } catch (error: any) {
    // toast.error(error.response.data.message)
    return error
  }
}

export const webHooksEvents = async (token: any) => {
  let encData = cryptoEncrypt({
    accesstoken: token,
    webhokSubscriptionId: `${process.env.REACT_APP_WEBHOOK_SUBSCRIPTION_ID}`,
  })
  try {
    let res = await axios.post(`${process.env.REACT_APP_API_BACKEND}/dwolla/fetch/webhook/events`, {
      data: encData,
    })
    return res.data.data._embedded
  } catch (error: any) {
    return error
  }
}

export const fundsTransfer = async (data: any, setSendFundBtn: any) => {
  let encData = cryptoEncrypt(data)
  try {
    let res = await axios.post(`${process.env.REACT_APP_API_BACKEND}/dwolla/transfers`, {
      data: encData,
    })
    return res
  } catch (error: any) {
    setSendFundBtn(false)
    toast.error(error.response.data.message)
    toast.error(error.response.data.data._embedded.errors[0].message)
    return error
  }
}

export const checkBalance = async (id: any, token: any) => {
  let encData = cryptoEncrypt({
    accesstoken: token,
  })
  try {
    let res = await axios.post(
      `${process.env.REACT_APP_API_BACKEND}/dwolla/funding-source/${id}/balance`,
      {
        data: encData,
      }
    )
    return res.data.data.balance.value
  } catch (error: any) {
    return error
  }
}
export const customerTransactions = async (id: any, token: any) => {
  let encData = cryptoEncrypt({
    accesstoken: token,
  })
  try {
    let res = await axios.post(
      `${process.env.REACT_APP_API_BACKEND}/dwolla/customer/${id}/transactions`,
      {
        data: encData,
      }
    )

    return res.data.data._embedded.transfers
  } catch (error: any) {
    return error
  }
}
export const createLenderFundingSource = async (id: any, data: any) => {
  let encData = cryptoEncrypt(data)
  try {
    let res = await axios.post(
      `${process.env.REACT_APP_API_BACKEND}/dwolla/customers/${id}/funding-source/lender`,
      {data: encData}
    )

    return res
  } catch (error: any) {
    if (error.response.data.data.message && error.response.data.data.code !== 'ValidationError') {
      toast.error(error.response.data.data.message)
    }
    if (error.response.data.data._embedded) {
      toast.error(error.response.data.data._embedded.errors[0].message)
    }

    return error
  }
}
export const removeFundingSource = async (id: any, token: any) => {
  let encData = cryptoEncrypt({accesstoken: token})
  try {
    let res = await axios.post(
      `${process.env.REACT_APP_API_BACKEND}/dwolla/remove/funding-source/${id}`,
      {data: encData}
    )
    // console.log('res', res.status === 200)
    if (res.status === 200) {
      toast.success('Funding Source Removed!')
    }
    return res
  } catch (error: any) {
    return error
  }
}

export const transfersFee = async (postData: any) => {
  let encData = cryptoEncrypt(postData)
  return await axios.post(`${process.env.REACT_APP_API_BACKEND}/dwolla/transfers/fee`, {
    data: encData,
  })
}
export const createPlaidLinkToken = async (postObj: any) => {
  let {customerId: userId, email} = postObj
  let encData = cryptoEncrypt({userId, email})

  return await axios.post(`${process.env.REACT_APP_API_BACKEND_V2}/plaid/link/token/create`, {
    data: encData,
  })
}

export const createPlaidProcessorToken = async (postObj: any) => {
  let {publicToken: public_token, accountId, pw_userId} = postObj
  let encData = cryptoEncrypt({public_token, accountId, pw_userId})

  return await axios.post(`${process.env.REACT_APP_API_BACKEND_V2}/plaid/create/processor/token`, {
    data: encData,
  })
}
export const createPlaidFundingSource = async (postObj: any) => {
  let encData = cryptoEncrypt(postObj)

  return await axios.post(
    `${process.env.REACT_APP_API_BACKEND_V2}/dwolla/create/funding/source/plaid`,
    {
      data: encData,
    }
  )
}
export const getLiabilities = async () => {
  let encData = cryptoEncrypt({
    access_token: process.env.REACT_APP_PLAID_ACCESS_TOKEN,
  })

  return await axios.post(`${process.env.REACT_APP_API_BACKEND_V2}/plaid/get/liabilities`, {
    data: encData,
  })
}
export const getPlaidAuth = async (token: any) => {
  let encData = cryptoEncrypt({
    access_token: token,
  })

  return await axios.post(
    `${process.env.REACT_APP_API_BACKEND_V2}/plaid/auth/get
  `,
    {
      data: encData,
    }
  )
}
