import { lazy, FC, Suspense, useEffect } from 'react'
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { MenuTestPage } from '../pages/MenuTestPage'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import ls from "localstorage-slim";
import { useDispatch } from 'react-redux'
import { Dispatch } from '@reduxjs/toolkit'
import { getCustomer, getDwalloToken, getFundingResources } from '../modules/_dwollaServices/dwolla'
import { getDwollaCustomer } from '../store/slice/dwollaCustomerSlice'
import { getDwollaFundingSources, getDwollaFundingSourcesBalance, getDwollaFundingSourcesBanks, getDwollaFundingSourcesLender } from '../store/slice/dwollaFundingSourcesSlice'
import { getCustomerData } from '../store/actions/getCustomerData'
import { initAxios } from '../utils/initAxios'
import { toast } from 'react-toastify'
import { sanitizeFundingSources } from '../utils/sanitizeFundingSources'

const PrivateRoutes = () => {
  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const CustomerProfilePage = lazy(() => import('../modules/accounts/CustomerProfilePage'))
  const DebtPage = lazy(() => import('../modules/debt/DebtPage'))
  const PaymentPage = lazy(() => import('../modules/payments/PaymentPage'))
  const AddBank = lazy(() => import('../modules/payments/components/AddBank'))
  const AddLenderBankPage = lazy(() => import('../modules/payments/AddLenderBankPage'))
  const FundingSourcePage = lazy(() => import('../modules/payments/FundingSourcePage'))
  const DebtResultPage = lazy(() => import('../modules/debt/DebtResultPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const dispatch: Dispatch<any> = useDispatch();
  // let customerInfo = useSelector((state: any) => state.getCustomerData)
  const navigate = useNavigate()

  // useEffect(() => {

  //   const handleTabClose = (event: any) => {
  //     event.preventDefault();


  //     console.log('beforeunload event triggered');

  //     return event.returnValue = 'Are you sure you want to exit?'
  //   };


  //   window.addEventListener('onbeforeunload', handleTabClose);

  //   return () => {
  //     window.removeEventListener('beforeunload', handleTabClose);
  //   };
  // }, []);

  // useEffect(() => {
  //   let duration = 300

  //   function updateTimer() {
  //     duration--;
  //     if (duration < 1) {
  //       ls.remove("u0@t");
  //       // localStorage.removeItem('persist:root');
  //       localStorage.clear();
  //       (window as any).location.reload();
  //     }
  //   }

  //   setInterval(updateTimer, 1000)
  //   function resetTimer() {
  //     duration = 300
  //   }
  //   window.addEventListener("mousemove", resetTimer);


  // }, [])

  const isCustomerOnDwolla = async (userEmail: any) => {
    let dwollaToken = await getDwalloToken()
    let customer: any = await getCustomer(dwollaToken, userEmail)


    if (customer !== undefined) {
      dispatch(getDwollaCustomer(customer))

      // let transactionResponse: any = await customerTransactions(customer.id, dwollaToken)
      // dispatch(getDwollaTransactions(transactionResponse))

      let data: any = await getFundingResources(customer.id, dwollaToken)
      let { newObjectData: allFundingSources, fundingSourcesBankOnly, fundingSourcesLender, balanceFundingSource } = sanitizeFundingSources(data)
      dispatch(getDwollaFundingSources(allFundingSources))
      dispatch(getDwollaFundingSourcesBanks(fundingSourcesBankOnly))
      dispatch(getDwollaFundingSourcesLender(fundingSourcesLender))
      dispatch(getDwollaFundingSourcesBalance(balanceFundingSource))

      // return
    } else {
      toast.info("Please Add SSN in Your Profile First.")
      setTimeout(() => {
        navigate("/customer-profile/overview")
      }, 5000);
    }
  }


  useEffect(() => {
    initAxios()
    dispatch(getCustomerData())
    //eslint-disable-next-line
  }, [])



  useEffect(() => {

    const auth: any = ls.get('u0@t', { decrypt: true })
    const email = JSON.parse(auth).data.email
    isCustomerOnDwolla(email)
    //eslint-disable-next-line
  }, [])


  useEffect(() => {
    setInterval(function () {
      let con = (window as any).navigator.onLine
      if (con === false) {
        navigate('/error/connection-lost')
      }
    }, 3000);

  }, [])

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to={'/debt-resolution/debt'} />} />
        {/* Pages */}
        {/* <Route path='dashboard' element={<DashboardWrapper />} /> */}
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        CustomerProfile
        <Route
          path='customer-profile/*'
          element={
            <SuspensedView>
              <CustomerProfilePage />
            </SuspensedView>
          }
        />
        {/* <Route
          path='customer-profile/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        /> */}
        <Route
          path='/debt-resolution/debt/*'
          element={
            <SuspensedView>
              <DebtPage />
            </SuspensedView>
          }
        />
        <Route
          path='/debt-resolution/debt/result'
          element={
            <SuspensedView>
              <DebtResultPage />
            </SuspensedView>
          }
        />

        <Route
          path='/bank-details/add/*'
          element={
            <SuspensedView>
              <PaymentPage />
            </SuspensedView>
          }
        />
        <Route
          path='/bank-details/add/bank'
          element={
            <SuspensedView>
              <AddBank />
            </SuspensedView>
          }
        />
        <Route
          path='/bank-details/lender-bank/*'
          element={
            <SuspensedView>
              <AddLenderBankPage />
            </SuspensedView>
          }
        />

        <Route
          path='/bank-details/*'
          element={
            <SuspensedView>
              <FundingSourcePage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
