import React, { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { useFormik } from 'formik'
// import { requestPassword } from '../core/_requests'
import axios from 'axios'
import { cryptoEncrypt } from '../../../utils/dataTransit'
import { toast } from 'react-toastify'

const initialValues = {


  password: "",
  confirmPassword: "",
  email: ""

}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
})

export function ResetPassword() {
  const [loading, setLoading] = useState(false)
  // const [tokenState, setTokenState] = useState("")
  // const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [passwordState, setPasswordState] = useState("");
  const [confirmPasswordState, setConfirmPasswordState] = useState("");



  const [passwordType, setPasswordType] = useState("password")
  const [confirmPasswordType, setConfirmPasswordType] = useState("password")

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text")
      return;
    }
    setPasswordType("password")
  }
  const toggleConfirmPassword = () => {
    if (confirmPasswordType === "password") {
      setConfirmPasswordType("text")
      return;
    }
    setConfirmPasswordType("password")
  }



  let navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams();
  let token = searchParams.get("token")



  const resetPassword = async (postData: any) => {
    let encData = cryptoEncrypt(postData)
    try {
      let res = await axios.post(`${process.env.REACT_APP_API_BACKEND}/auth/reset/password?token=${token}`,
        { data: encData }
      )
      // console.log(res.data);

      localStorage.setItem("e", res.data.email)
      toast.success(res.data.message + " Scan QR Code.")
      setTimeout(() => {
        navigate("/auth/two-step-authentication")
      }, 6000);

    } catch (error: any) {
      toast.error(error.response.data.message)
      return error
    }
  }


  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values) => {
      setLoading(true)

      let updatedValues = { ...values }
      updatedValues.password = passwordState;
      updatedValues.confirmPassword = confirmPasswordState;
      if (
        passwordState === confirmPasswordState &&
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/.test(
          passwordState
        )
      ) {

        await resetPassword(updatedValues)
      }

      //   setHasErrors(undefined)
      //   setTimeout(() => {
      //     requestPassword(values.email)
      //       .then(({ data: { result } }) => {
      //         setHasErrors(false)
      //         setLoading(false)
      //       })
      //       .catch(() => {
      //         setHasErrors(true)
      //         setLoading(false)
      //         setSubmitting(false)
      //         setStatus('The login detail is incorrect')
      //       })
      //   }, 1000)
    },
  })


  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-dark mb-3'>Reset Password</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='text-gray-400 fw-bold fs-4'>Enter your email and new password to reset your old password.</div>
          {/* end::Link */}
        </div>

        {/* begin::Title */}
        {/* {hasErrors === true && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>
              Sorry, looks like there are some errors detected, please try again.
            </div>
          </div>
        )}

        {hasErrors === false && (
          <div className='mb-10 bg-light-info p-8 rounded'>
            <div className='text-info'>Sent password reset. Please check your email</div>
          </div>
        )} */}
        {/* end::Title */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>
          <input
            type='email'
            placeholder=''
            autoComplete='off'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              { 'is-invalid': formik.touched.email && formik.errors.email },
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert' style={{ color: 'red' }}>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>New Password</label>
          <div className="input-group">
            <input
              type={passwordType}
              onChange={(e: any) =>
                setPasswordState(e.target.value)
              }
              // maxLength={8}
              value={passwordState}
              placeholder=''
              autoComplete='off'
              // {...formik.getFieldProps('password')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                { 'is-invalid': formik.touched.password && formik.errors.password },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            <div className="input-group-btn">
              <button className="btn border-end border-top border-bottom" style={{ borderRadius: "0px 10px 10px 0px" }} type="button" onClick={togglePassword}>
                {passwordType === "password" ? <i className="bi bi-eye-slash"></i> : <i className="bi bi-eye"></i>}
              </button>
            </div>
          </div>
          {/* {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.password}</span>
              </div>
            </div>
          )}
          
 */}


          {passwordState.length === 0 ? (
            <div
              className="error"
              style={{
                color: "red",
                fontSize: "1rem",
              }}
            >
              Please Enter your password.
            </div>
          ) : /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/.test(
            passwordState
          ) ? null : (
            <div
              className="error"
              style={{
                color: "red",
                fontSize: "1rem",
              }}
            >
              Must Contain One
              Uppercase, One Lowercase, One Number and
              One Special Case Character.
            </div>
          )}
          {/* ============================================================= */}


        </div>
        {/* end::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>Confirm Password</label>
          <div className="input-group">
            <input
              type={confirmPasswordType}

              // maxLength={8}
              onChange={(e: any) =>
                setConfirmPasswordState(e.target.value)
              }
              placeholder=''
              autoComplete='off'
              // {...formik.getFieldProps('password')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                { 'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword },
                {
                  'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword,
                }
              )}
            />
            <div className="input-group-btn">
              <button className="btn border-end border-top border-bottom" style={{ borderRadius: "0px 10px 10px 0px" }} type="button" onClick={toggleConfirmPassword}>
                {confirmPasswordType === "password" ? <i className="bi bi-eye-slash"></i> : <i className="bi bi-eye"></i>}
              </button>
            </div>
          </div>
          {/* {formik.touched.confirmPassword && formik.errors.confirmPassword && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.confirmPassword}</span>
              </div>
            </div>
          )} */}
          {passwordState ===
            confirmPasswordState ? null : (
            <div
              className="error"
              style={{
                color: "red",
                fontSize: "1rem",
              }}
            >
              Passwords must be same.
            </div>
          )}

          {/* =================================================== */}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <button
            type='submit'
            id='kt_password_reset_submit'
            className='btn btn-lg btn-primary fw-bolder me-4'
          >
            <span className='indicator-label'>Submit</span>
            {loading && (
              <span className='indicator-progress'>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_password_reset_form_cancel_button'
              className='btn btn-lg btn-light-primary fw-bolder'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              Cancel
            </button>
          </Link>{' '}
        </div>
        {/* end::Form group */}
      </form>
    </>
  )
}
