import {createAsyncThunk} from '@reduxjs/toolkit'
// import instance from '../../utils/axiosInstance'
import axios from 'axios'
import ls from 'localstorage-slim'
export const getPackagePlan = createAsyncThunk<any>('getPackagePlan', async () => {
  const userData: any = ls.get('u0@t', {decrypt: true})
  const data = JSON.parse(userData)
  const res: any = await axios.get(
    `${process.env.REACT_APP_API_BACKEND}/debt-stake-subscription/${data.data.id}`
  )
  return res.data.data
})
