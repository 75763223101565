/* eslint-disable react/jsx-no-target-blank */
import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../helpers'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'
// import { getCustomer, getDwalloToken, getFundingResources } from '../../../../app/modules/_dwollaServices/dwolla'
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch } from '@reduxjs/toolkit'
// import axios from 'axios'
import { getCustomerData } from '../../../../app/store/actions/getCustomerData'
// import ls from "localstorage-slim";
import { initAxios } from '../../../../app/utils/initAxios'
// import { getLoans } from '../../../../app/store/actions/loansAction'

export function AsideMenuMain() {
  const intl = useIntl()
  // const [customerState, setCustomerState] = useState(undefined)
  // const [fundingSources, setFundingSources] = useState(undefined)
  const dispatch: Dispatch<any> = useDispatch();
  // let customerInfo = useSelector((state: any) => state.getCustomerData)
  const loansReducer = useSelector((state: any) => state.loansReducer)
  let loansFromApi
  if (loansReducer) {
    loansFromApi = loansReducer.loans.data?.data
  }


  const customerReducer = useSelector((state: any) => state.dwollaCustomerReducer)
  let status = customerReducer?.dwollaCustomer?.status || "n/a"

  // const fundingSourcesReducer = useSelector((state: any) => state.dwollaFundingSourcesReducer)
  // let { dwollaFundingSourcesBanks: fundingSources } = fundingSourcesReducer

  // const toggleBankDetailsLink = async (email: any) => {
  //   let dwollaToken = await getDwalloToken()
  //   let customer: any = await getCustomer(dwollaToken, email)
  //   if (customer) {
  //     let status = customer?.status
  //     let customerId = customer.id
  //     setCustomerState(status)


  //     let data: any = await getFundingResources(customerId, dwollaToken)
  //     const newObjectData: any = {};
  //     delete Object.assign(newObjectData, data, { ["fundingSources"]: data["funding-sources"] })["funding-sources"];
  //     let { fundingSources } = newObjectData
  //     let bankFundingSource = fundingSources.find((elem: any) => elem.type === "bank")

  //     setFundingSources(bankFundingSource)
  //   }

  // }


  useEffect(() => {
    initAxios()
    dispatch(getCustomerData())
    //eslint-disable-next-line
  }, [])

  // useEffect(() => {
  //   dispatch(getLoans())
  // }, [loansFromApi])


  // useEffect(() => {
  //   if (customerInfo.loading === false) {
  //     let email: any = customerInfo.customer.data.data.email
  //     toggleBankDetailsLink(email)
  //   }
  // }, [customerInfo])

  return (
    <>
      {/* <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
        fontIcon='bi-app-indicator'
      /> */}
      {/* <AsideMenuItem
        to='/builder'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Layout Builder'
        fontIcon='bi-layers'
      /> */}
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
            Student Loans Creditor
          </span>
        </div>
      </div>
      {status === "verified" ?
        <>
          <AsideMenuItemWithSub
            to='/crafted/pages'
            title='Debt Resolution'
            fontIcon='bi-archive'
            icon='/media/icons/duotune/general/gen022.svg'
          >
            <AsideMenuItem to='/debt-resolution/debt' title='Debt' hasBullet={true} />

            {loansFromApi?.totalInterestPaid > 0 ?
              <AsideMenuItem to='/debt-resolution/debt/result' title='Package Plan' hasBullet={true} /> : null
            }
            {/* <AsideMenuItemWithSub to='/crafted/pages/profile' title='Debt' hasBullet={true}> */}
            {/* <AsideMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/campaigns' title='Campaigns' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/documents' title='Documents' hasBullet={true} />
          <AsideMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          /> */}
            {/* </AsideMenuItemWithSub> */}

            {/* <AsideMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <AsideMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <AsideMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </AsideMenuItemWithSub> */}
          </AsideMenuItemWithSub>

          <AsideMenuItemWithSub
            to='/crafted/pages'
            title='Bank Details'
            fontIcon='bi-archive'
            icon='/media/icons/duotune/ecommerce/ecm004.svg'
          >
            {/* <AsideMenuItem to='/bank-details/add/bank' title='Add Bank (with Plaid)' hasBullet={true} /> */}
            {/* <AsideMenuItem to='/bank-details/add' title='Add Funding Source (Depreciated)' hasBullet={true} /> */}
            {/* <AsideMenuItem to='/bank-details/lender-bank' title='Add Lender Bank' hasBullet={true} /> */}

            <AsideMenuItem to='/bank-details/funding-source' title='My Account Details' hasBullet={true} />

            {/* <AsideMenuItem to='/debt-resolution/debt/result' title='Package Plan' hasBullet={true} /> */}
            {/* <AsideMenuItemWithSub to='/crafted/pages/profile' title='Debt' hasBullet={true}> */}
            {/* <AsideMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/campaigns' title='Campaigns' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/documents' title='Documents' hasBullet={true} />
          <AsideMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          /> */}
            {/* </AsideMenuItemWithSub> */}

            {/* <AsideMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <AsideMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <AsideMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </AsideMenuItemWithSub> */}
          </AsideMenuItemWithSub>
        </>
        : null}
      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/crafted/pages'
        title='Pages'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <AsideMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <AsideMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/campaigns' title='Campaigns' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/documents' title='Documents' hasBullet={true} />
          <AsideMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <AsideMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <AsideMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/customer-profiles'
        title='Accounts'
        icon='/media/icons/duotune/communication/com006.svg'
        fontIcon='bi-person'
      >
        <AsideMenuItem to='/customer-profile/overview' title='Overview' hasBullet={true} />
        <AsideMenuItem to='/customer-profile/settings' title='Settings' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/error'
        title='Errors'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/general/gen040.svg'
      >
        <AsideMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <AsideMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='/media/icons/duotune/general/gen025.svg'
        fontIcon='bi-layers'
      >
        <AsideMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </AsideMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com012.svg'
      >
        <AsideMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItem
        to='/apps/user-management/users'
        icon='/media/icons/duotune/general/gen051.svg'
        title='User management'
        fontIcon='bi-layers'
      /> */}
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
      <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL || "https://practicewisedds.statuspage.io/"}
        >
          <span className='menu-icon'>
            <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div>
    </>
  )
}
