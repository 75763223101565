import {createSlice} from '@reduxjs/toolkit'

const initialState: any = {
  image: false,
}

export const imageSlice = createSlice({
  name: 'image',
  initialState,
  reducers: {
    setImageResponse: (state, action) => {
      state.image = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {setImageResponse} = imageSlice.actions

export default imageSlice.reducer
