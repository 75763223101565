import {createSlice} from '@reduxjs/toolkit'

const initialState: any = {
  dwollaFundingSources: {},
  dwollaFundingSourcesBanks: {},
  dwollaFundingSourcesLender: {},
  dwollaFundingSourcesBalance: {},
}

export const dwollaFundingSourcesSlice = createSlice({
  name: 'dwollaFundingSources',
  initialState,
  reducers: {
    getDwollaFundingSources: (state, action) => {
      state.dwollaFundingSources = action.payload
    },
    getDwollaFundingSourcesBanks: (state, action) => {
      state.dwollaFundingSourcesBanks = action.payload
    },
    getDwollaFundingSourcesLender: (state, action) => {
      state.dwollaFundingSourcesLender = action.payload
    },
    getDwollaFundingSourcesBalance: (state, action) => {
      state.dwollaFundingSourcesBalance = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  getDwollaFundingSources,
  getDwollaFundingSourcesBanks,
  getDwollaFundingSourcesLender,
  getDwollaFundingSourcesBalance,
} = dwollaFundingSourcesSlice.actions

export default dwollaFundingSourcesSlice.reducer
