export const sanitizeFundingSources = (data: any) => {
  const newObjectData: any = {}

  delete Object.assign(newObjectData, data, {['fundingSources']: data['funding-sources']})[
    'funding-sources'
  ]
  let {fundingSources} = newObjectData
  let fundingSourcesBankOnly: any = []
  let fundingSourcesLender: any = []
  for (let index = 0; index < fundingSources.length; index++) {
    if (fundingSources[index].type === 'bank') {
      fundingSourcesBankOnly.push(fundingSources[index])
    }
    if (fundingSources[index].type === 'bank' && fundingSources[index].bankAccountType === 'loan') {
      fundingSourcesLender.push(fundingSources[index])
    }
  }
  let balanceFundingSource = fundingSources.find((elem: any) => elem.type === 'balance')
  return {newObjectData, fundingSourcesBankOnly, fundingSourcesLender, balanceFundingSource}
}
