import {createSlice} from '@reduxjs/toolkit'
import {getLoans} from '../actions/loansAction'

const initialState: any = {
  loans: {},
  loading: false,
  errors: {},
}

export const loansSlice = createSlice({
  name: 'values',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLoans.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(getLoans.fulfilled, (state, action) => {
      state.loans = {...action.payload}
      state.loading = false
    })
    builder.addCase(getLoans.rejected, (state, action) => {
      state.errors = action.payload
    })
  },
})

// Action creators are generated for each case reducer function
// export const {addLoans} = loansSlice.actions

export default loansSlice.reducer
