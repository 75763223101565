import {createSlice} from '@reduxjs/toolkit'

const initialState: any = {
  LoanAccounts: {},
}

export const spinwheelLoanAccountsSlice = createSlice({
  name: 'LoanAccounts',
  initialState,
  reducers: {
    setLoanAccounts: (state, action) => {
      state.LoanAccounts = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {setLoanAccounts} = spinwheelLoanAccountsSlice.actions

export default spinwheelLoanAccountsSlice.reducer
