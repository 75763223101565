import {createAsyncThunk} from '@reduxjs/toolkit'
// import instance from '../../utils/axiosInstance'
import axios from 'axios'

import ls from 'localstorage-slim'
export const getCustomerData = createAsyncThunk<any>('getCustomerData', async () => {
  const auth: any = ls.get('u0@t', {decrypt: true})

  const data = JSON.parse(auth).data
  try {
    let res = await axios.get(`${process.env.REACT_APP_API_BACKEND}/user/${data.id}`)

    return res
  } catch (error: any) {
    // console.log('error token: ', error.response.status)
    if (error.response.data.message === 'JsonWebTokenError') {
      ls.remove('u0@t')
      localStorage.removeItem('persist:root')
      window.location.reload()
    } else if (error.response.data.message === 'TokenExpiredError') {
      ls.remove('u0@t')
      localStorage.removeItem('persist:root')
      window.location.reload()
    } else if (error.response.status === 401 || error.response.status === 404) {
      ls.remove('u0@t')
      localStorage.removeItem('persist:root')
      window.location.reload()
    }
    return error
  }
})
