import * as crypto from 'crypto-js'

export const cryptoEncrypt = (data: any) => {
  let encryptedData = crypto.AES.encrypt(
    JSON.stringify(data),
    `${process.env.REACT_APP_CRYPTO_KEY}`
  ).toString()
  return encryptedData
}

export const cryptoDecrypt = (data: any) => {
  let encryptedData = crypto.AES.decrypt(data, `${process.env.REACT_APP_CRYPTO_KEY}`)
  let decryptedData = encryptedData.toString(crypto.enc.Utf8)
  // let newData = JSON.parse(decryptedData)
  return decryptedData
}

export const encryptEmail = (email: string) => {
  const encrypted = crypto.AES.encrypt(email, `${process.env.REACT_APP_CRYPTO_KEY}`).toString()
  return encrypted
}
