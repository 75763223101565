import { useState, useEffect } from 'react'
import * as Yup from 'yup'
// import clsx from 'clsx'
import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
// import { requestPassword } from '../core/_requests'
import axios from 'axios'
import { cryptoEncrypt } from '../../../utils/dataTransit'
import { toast } from 'react-toastify'
import OtpInput from 'react-otp-input';
import "../../../css/otp-input.css"

const initialValues = {
  qrCode: '',
}

const forgotPasswordSchema = Yup.object().shape({
  // email: Yup.string()
  //   .email('Wrong email format')
  //   .min(3, 'Minimum 3 symbols')
  //   .max(50, 'Maximum 50 symbols')
  //   .required('Email is required'),
})

export function TwoStepAuthentication() {
  const [loading, setLoading] = useState(false)
  const [codeUrl, setCodeUrl] = useState("")
  const [otpCode, setOtpCode] = useState("")
  // const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)


  // const [searchParams, setSearchParams] = useSearchParams();
  // let paramEmail = searchParams.get("e")

  let resEmail: any = localStorage.getItem("e")

  // let linkToSplit = link.split('?')[1]
  // let paramEmail = linkToSplit.split('=')[1]
  // let decEmail = cryptoDecrypt(paramEmail)

  let navigate = useNavigate()
  const verifyLogin = async (code: any) => {
    let encData = cryptoEncrypt({
      email: resEmail,
      otp: code,
    })
    try {
      await axios.post(`${process.env.REACT_APP_API_BACKEND}/auth/verify/login/2fa`, { data: encData })
      toast.success("Code Verified! You can login with New Password and Code.");
      setTimeout(() => {
        localStorage.removeItem("e");
        navigate("/auth")
      }, 6000);

    } catch (error: any) {
      setOtpCode("")
      toast.error(error.response.data.message);
      return error
    }
  }

  const initiateQRCode = async (email: string) => {
    let encData = cryptoEncrypt({
      email
    })
    try {
      let res = await axios.post(`${process.env.REACT_APP_API_BACKEND}/auth/initiate/2fa`, { data: encData })
      return res.data.qrcode

    } catch (error: any) {
      return error
    }
  }


  const qrCodeHandler = async () => {
    let codeUrl = await initiateQRCode(resEmail)
    setCodeUrl(codeUrl)

  }
  useEffect(() => {
    qrCodeHandler()
    //eslint-disable-next-line
  }, [])


  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values) => {
      setLoading(true)

      values.qrCode = otpCode
      await verifyLogin(values.qrCode)


    },
  })

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-dark mb-3'>Two-Step Authentication</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='text-gray-400 fw-bold fs-4'>Scan QR with Authenticator Mobile App and Enter Code.</div>
          {/* end::Link */}
        </div>


        {/* end::Title */}

        {/* begin::Form group */}
        <div className='text-center'>

          <img src={codeUrl}
            style={{ width: "200px", height: "200px" }}
            alt="" />
        </div>
        <div className='fv-row mb-10 d-flex justify-content-center my-5'>
          {/* <label className='form-label fw-bolder text-gray-900 fs-6'>Enter Code</label> */}
          <OtpInput
            value={otpCode}
            onChange={(otp: any) => setOtpCode(otp)}
            numInputs={6}
            separator={<span>-</span>}
            inputStyle="otp-input-style"

          />

          {/* <input
            type='text'
            placeholder=''
            autoComplete='off'
            {...formik.getFieldProps('qrCode')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              { 'is-invalid': formik.touched.qrCode && formik.errors.qrCode },
              {
                'is-valid': formik.touched.qrCode && !formik.errors.qrCode,
              }
            )}
          />
          {formik.touched.qrCode && formik.errors.qrCode && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.qrCode}</span>
              </div>
            </div>
          )} */}
        </div>

        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <button
            type='submit'
            id='kt_password_reset_submit'
            className='btn btn-lg btn-primary fw-bolder me-4'
          >
            <span className='indicator-label'>Submit</span>
            {loading && (
              <span className='indicator-progress'>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_password_reset_form_cancel_button'
              className='btn btn-lg btn-light-primary fw-bolder'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              Cancel
            </button>
          </Link>{' '}
        </div>
        {/* end::Form group */}
      </form>
    </>
  )
}
